<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-sheet class="pa-4">
          <v-avatar color="grey darken-1" size="60"
            ><img :src="data_user.avatar" alt="Avatar" srcset="" width="60"
          /></v-avatar>
        </v-sheet>
      </v-col>
      <v-col cols="9" style="height: 60px" class="mt-4">
        <h4>{{data_user.username}}</h4>
        
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="height: 60px" class="mt-4">
        <v-text-field
          v-model="old_password"
          label="Senha antiga"
          type="password"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" style="height: 60px" class="mt-4">
        <v-text-field
          v-model="new_password"
          label="Nova senha"
          type="password"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" style="height: 60px" class="mt-4">
        <v-text-field
          v-model="confirmation_new_password"
          label="Confirmar nova senha"
          type="password"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" style="height: 60px" class="mt-4">
        <v-btn color="primary" block>SALVE</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["data_user"],
  data() {
    return {};
  },
  created(){
  },
  methods: {},
};
</script>